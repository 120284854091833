<template>
  <form
    ref="form"
    class="b-form-callback"
    novalidate
    @submit.prevent="onSubmit"
  >
    <div class="b-form-callback__row">
      <input-phone
        v-model.trim="$v.form.phone.$model"
        :focus="inputFocus"
        required
        class="callback-phone-input"
        return-focus
        @input="ifStart"
      />
    </div>
    <div class="b-form-callback__row">
      <button
        class="b-form-callback__button"
        type="submit"
        variant="action"
      >
        {{ ok }}
      </button>
    </div>
  </form>
</template>

<script>
  // Components
  import InputPhone from '../components/BaseInputMaskedPhone.vue'
  // Validation
  import {validationMixin} from 'vuelidate'
  import {required} from 'vuelidate/lib/validators'

  export default {
    name: 'BaseFormCallBack',
    components: {
      InputPhone
    },
    mixins: [validationMixin],
    props: {
      ok: {
        type: String,
        default: 'Перезвоните мне'
      }
    },
    data: () => ({
      inputFocus: false,
      started: false,
      form: {
        phone: '',
        type_id: '4'
      }
    }),
    validations: {
      form: {
        phone: {
          required
        }
      }
    },
    methods: {
      ifStart() {
        if (this.started) return;
        this.$emit('started')
      },
      onSubmit(ev) {
        if (!this.$v.$anyError) {
          this.$emit('submit', this.form, this.$refs.form, ev);
          this.$store.dispatch('sendFormCallBack', this.form)
            .then(({statusText}) => {
              if (statusText === 'OK') {
                this.$bvToast.toast('Менеджер свяжется с вами в ближайшее время', {
                  title: `Ваша заявка принята`,
                  variant: 'success',
                  solid: true
                })
              } else {
                this.$bvToast.toast('Попробуйте позже', {
                  title: `Не удалось отправить форму`,
                  variant: 'danger',
                  solid: true
                })
              }
            })
            .catch((e) => {
              /* eslint no-console: [1, {allow: ["warn", "error"]}] */
              console.error(e);
              this.$bvToast.toast('Попробуйте позже', {
                title: `Не удалось отправить форму`,
                variant: 'danger',
                solid: true
              })
            });
          this.clear()
        }
      },
      clear() {
        this.form = {
          phone: '',
          type: '4'
        };
        this.$emit('clear');
        this.$v.$reset()
      }
    }
  }
</script>

<style lang="scss">
  .b-form-callback {
    text-align: center;

    &__row {
      margin-top: 1.25rem;
    }

    &__button {
      display: inline-block;
      font-weight: 400;
      text-align: center;
      vertical-align: middle;
      user-select: none;
      /*background-color: transparent;*/
      border: 1px solid transparent;
      padding: .375rem .75rem;
      font-size: 1em;
      line-height: 1.5;
      border-radius: .25rem;
      transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
      background-color: #0a68ab;
      color: white;
    }

  }
</style>
