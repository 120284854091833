<template>
  <form @submit.prevent="submit">
    <div class="b-callback-widget__row">
      <button
        class="b-callback-widget__button b-callback-widget__button--yes"
        @click.prevent="vote('Yes')"
      >
        {{ $t('message.yes')}}
      </button>
      <button
        class="b-callback-widget__button b-callback-widget__button--no"
        @click.prevent="vote('No')"
      >
        {{ $t('message.no')}}
      </button>
    </div>
  </form>
</template>

<script>
  export default {
    name: "FormVote",
    methods: {
      vote(w) {
        this.$emit('vote', w);
      },
      submit() {
        this.$ga.event('form', 'zayavka');
        this.$emit('submit', '')
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>
