<template>
  <div class="b-callback">
    <i class="b-callback__icon"/>
    <div class="b-callback__text">
      <slot>
        <span class="b-callback__text-lg">{{ $t('message.base_callback__text_1')}}</span>
        <span class="b-callback__text-md">{{ $t('message.base_callback__text_2')}}</span>
        <span class="b-callback__text-sm">{{ $t('message.base_callback__text_3')}}</span>
      </slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BaseCallBack',
  };
</script>

<style lang="scss">
  .b-callback {
    display: flex;
    /* padding-left: 3.5em; */
    font-size: 0.8em;
    cursor: pointer;
  }

  .b-callback__icon {
    position: relative;
    display: block;
    width: 3.5em;
    height: 4.5em;
    background-image: url(../assets/phone.svg);
    background-repeat: no-repeat;
    background-position: top left;

    animation: ring 5000ms infinite;
    /* animation-delay: 3s, 2s; */
    /* animation-timing-function:  cubic-bezier(.68,-0.55,.31,1.31); */
  }

  .b-callback__text span {
    display: block;
    font-weight: 600;
    line-height: 1.5rem;
  }

  .b-callback__text-lg {
    font-size: 1.5em;
  }

  .b-callback__text-md {
    font-size: 1em;
  }

  .b-callback__text-sm {
    font-size: 0.75em;
  }

  @media screen and (min-width: 1300px) {
    .b-callback {
      font-size: 1em;
    }
  }

  @keyframes ring {
    0% {
      transform: rotate(0);
    }
    1% {
      transform: rotate(5deg);
    }
    2% {
      transform: rotate(-3deg);
    }
    3% {
      transform: rotate(4deg);
    }
    4% {
      transform: rotate(-3deg);
    }
    5% {
      transform: rotate(2deg);
    }
    6% {
      transform: rotate(-1deg);
    }
    7% {
      transform: rotate(6deg);
    }
    8% {
      transform: rotate(-6deg);
    }
    9% {
      transform: rotate(4deg);
    }
    10% {
      transform: rotate(-3deg);
    }
    11% {
      transform: rotate(2deg);
    }
    12% {
      transform: rotate(-1deg);
    }
    13% {
      transform: rotate(2deg);
    }
    14% {
      transform: rotate(-7deg);
    }
    15% {
      transform: rotate(5deg);
    }
    16% {
      transform: rotate(-4deg);
    }
    17% {
      transform: rotate(3deg);
    }
    18% {
      transform: rotate(-2deg);
    }
    19% {
      transform: rotate(1deg);
    }
    20% {
      transform: rotate(-1deg);
    }
  }

  @keyframes phone-outer {
    0% {
      transform: translate3d(0, 0, 0) scale(1);
      box-shadow: 0 0 0 0 rgba(#3498db, 0),
      0 0.05em 0.1em rgba(#000000, 0.2);
    }
    33.3333% {
      transform: translate3d(0, 0, 0) scale(1.1);
      box-shadow: 0 0 0 0 rgba(#3498db, 0.1),
      0 0.05em 0.1em rgba(#000000, 0.5);
    }
    66.6666% {
      transform: translate3d(0, 0, 0) scale(1);
      box-shadow: 0 0 0 0.5em rgba(#3498db, 0),
      0 0.05em 0.1em rgba(#000000, 0.2);
    }
    100% {
      transform: translate3d(0, 0, 0) scale(1);
      box-shadow: 0 0 0 0 rgba(#3498db, 0),
      0 0.05em 0.1em rgba(#000000, 0.2);
    }
  }

  @keyframes phone-icon {
    0% {
      transform: translate3d(0, 0, 0);
    }
    2% {
      transform: translate3d(0.01em, 0, 0);
    }
    4% {
      transform: translate3d(-0.01em, 0, 0);
    }
    6% {
      transform: translate3d(0.01em, 0, 0);
    }
    8% {
      transform: translate3d(-0.01em, 0, 0);
    }
    10% {
      transform: translate3d(0.01em, 0, 0);
    }
    12% {
      transform: translate3d(-0.01em, 0, 0);
    }
    14% {
      transform: translate3d(0.01em, 0, 0);
    }
    16% {
      transform: translate3d(-0.01em, 0, 0);
    }
    18% {
      transform: translate3d(0.01em, 0, 0);
    }
    20% {
      transform: translate3d(-0.01em, 0, 0);
    }
    22% {
      transform: translate3d(0.01em, 0, 0);
    }
    24% {
      transform: translate3d(-0.01em, 0, 0);
    }
    26% {
      transform: translate3d(0.01em, 0, 0);
    }
    28% {
      transform: translate3d(-0.01em, 0, 0);
    }
    30% {
      transform: translate3d(0.01em, 0, 0);
    }
    32% {
      transform: translate3d(-0.01em, 0, 0);
    }
    34% {
      transform: translate3d(0.01em, 0, 0);
    }
    36% {
      transform: translate3d(-0.01em, 0, 0);
    }
    38% {
      transform: translate3d(0.01em, 0, 0);
    }
    40% {
      transform: translate3d(-0.01em, 0, 0);
    }
    42% {
      transform: translate3d(0.01em, 0, 0);
    }
    44% {
      transform: translate3d(-0.01em, 0, 0);
    }
    46% {
      transform: translate3d(0, 0, 0);
    }
  }
</style>
