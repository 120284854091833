<template>
  <div class="b-callback-widget">

    <template v-if="isWorkTime">
      <div
        v-if="beforeStart"
        class="b-callback-widget__state"
      >
        <h5 class="b-callback-widget__headline">
          {{ $t('message.base_callback_widget__header')}}
        </h5>
        <p class="b-callback-widget__text">
          {{ $t('message.base_callback_widget__text')}}
        </p>
        <BaseFormCallBack
          :ok="ok"
          class="text-center"
          @started="onStart"
          @submit="startCounter"
        />
        <p class="b-callback-widget__ds">
          {{ $t('message.base_callback_widget__its_free')}}
        </p>
      </div>


      <div
        v-if="isStarted"
        class="b-callback-widget__state"
      >
        <div class="b-callback-widget__counter">
          <span class="b-callback-widget__header">
            {{ $t('message.base_callback_widget__call_you')}}
          </span>
          <span v-if="isWorkTime" class="b-callback-widget__counter-number">
            <counter :time="innerCounter"/>
          </span>
          <span v-else>
            {{ callYouNext }}
          </span>
        </div>
      </div>


      <div
        v-if="isOver"
        class="b-callback-widget__state"
      >
        <div class="b-callback-widget__text">
          <slot name="msg">
            {{ $t('message.base_callback_widget__is_success')}}
          </slot>
        </div>
        <form-vote
          @vote="onVote"
          @submit="onFinish"
        />
      </div>
    </template>
    <template v-else>
      <div
        v-if="beforeStart"
        class="b-callback-widget__state"
      >
        <h5 class="b-callback-widget__headline">
          {{ $t('message.base_callback_widget__nobody')}}
        </h5>
        <p class="b-callback-widget__text">
          {{ $t('message.base_callback_widget__nobody_text')}}<br><b>{{ callYouNext }}</b>
        </p>
        <BaseFormCallBack
          :ok="ok"
          class="text-center"
          @started="onStart"
          @submit="finish"
        />
        <p class="b-callback-widget__ds">
          {{ $t('message.base_callback_widget__its_free')}}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
  import eq from 'lodash/fp/eq'
  import lte from 'lodash/fp/lte'
  import axios from 'axios'

  import dayjs from 'dayjs'
  import 'dayjs/locale/ru'

  dayjs.locale('ru');

  // Components
  import BaseFormCallBack from '../components/BaseFormCallBack.vue'
  import Counter from '../components/Counter.vue'
  import FormVote from "./FormVote";

  export default {
    name: 'BaseCallbackWidget',
    components: {
      FormVote,
      BaseFormCallBack, Counter
    },
    props: {
      counter: {
        type: Number,
        default: 30
      },
      ok: {
        type: String,
        default: ''
      }
    },
    data: () => ({
      isWorkTime: false,
      next: '',
      innerCounter: -1,
      timer: null,
      date: Math.trunc(new Date().getTime() / 1000) + 30
    }),
    computed: {
      isOver() {
        return eq(this.innerCounter, 0) && eq(this.timer, null)
      },
      isStarted() {
        return !eq(this.timer, null)
      },
      beforeStart() {
        return !this.isStarted && eq(this.innerCounter, this.counter)
      },
      callYouNext() {
        return dayjs(this.next).format('DD.MM.YYYY');
      }
    },
    watch: {
      counter: (counter) => {
        this.innerCounter = counter;
      }
    },
    mounted() {
      this.resetCounter();
      axios.post('https://bp.asm.ru/api/date/work', {})
        .then(({data}) => data)
        .then(({is_worktime, next}) => {
          this.isWorkTime = !!is_worktime;
          this.next = next;
        })
    },
    methods: {

      resetCounter() {
        this.innerCounter = this.counter
      },
      startCounter() {
        this.resetCounter();
        this.timer = setInterval(() => {
          if (lte(this.innerCounter, 0)) {

            clearTimeout(this.timer);
            this.timer = null;
            return
          }
          this.innerCounter--
        }, 1000)
      },

      finish() {
        this.onFinish();
        this.$emit('exit');
      },

      onVote(w) {
        if (w === 'Yes') this.$emit('exit');
        this.resetCounter();
        this.$ga.event('callback', w)
      },
      onStart() {
        this.$ga.event('callback_form', 'started')
      },
      onFinish() {
        this.$ga.event('callback_form', 'finished');
      }
    }
  }
</script>

<style lang="scss">
  .callback-phone-input {
    max-width: 16em;
    margin: auto;
  }

  .b-callback-widget {

    &__headline {
      font-size: 1.25em;
      margin-top: 0;
      margin-bottom: .5rem;
      text-align: center;
    }

    &__ds {
      padding-top: .5em;
      font-weight: 300;
      text-align: center;
    }

    &__row {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-content: center;
    }

    &__button {
      display: inline-block;
      font-weight: 400;
      color: #212529;
      text-align: center;
      vertical-align: middle;
      user-select: none;
      background-color: transparent;
      border: 1px solid transparent;
      padding: .375rem .75rem;
      font-size: 1.25em;
      line-height: 1.5;
      border-radius: .25rem;
      transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
      margin-right: 1em;

      &:last-child {
        margin-right: 0;
      }

      &--yes {
        background-color: var(--success);
        color: var(--white);
      }

      &--no {
        background-color: var(--dark);
        color: var(--white);
      }
    }
  }

  .b-callback-widget__state {
    margin: 1.6em;
  }

  .b-callback-widget__header {
    display: block;
    text-align: center;
    font-size: 1.6em;
    font-weight: 300;
    margin-bottom: 1em;
  }

  .b-callback-widget__text {
    display: block;
    text-align: center;
    font-size: 1.25em;
    margin-bottom: 1em;
  }

  .b-callback-widget__counter-number {
    display: block;
    margin: auto;
    text-align: center;
    font-size: 1.6em;
  }
</style>
