import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import {event} from 'vue-analytics';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang: document.documentElement.lang
  },
  mutations: {},
  actions: {
    getWorkTime() {
      return axios.get('http://bp.asm.ru/api/date/work')
        .then(({data}) => console.log(data))
        .then(({is_worktime, next}) => {
          this.isWorkTime = !!is_worktime;
          this.next = next;
        })
    },
    sendFormCallBack(__, payload) {
      /**
       * Данные
       * @type {{lead: {utm_stack: string}, js_on: number}}
       */
      const data = {
        lead: {
          ...payload,
          utm_stack: localStorage.getItem('utm_stack')
        },
        js_on: 1
      };

      /**
       * Подключение riostat
       */
      if (typeof getCookie === 'function') {
        data.roistat = {
          // eslint-disable-next-line no-undef
          id: getCookie('roistat_visit')
        }
      }

      return axios.post('https://bp.asm.ru/request/json/', data, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }).then((result) => {
        event('form', 'zayavka', 'callback');
        return result;
      }).catch((error) => {
        return error;
      });
      // return Promise.resolve({statusText: 'OK'})
    },
  }
})
