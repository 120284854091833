import Vue from 'vue'
import App from './App.vue'
import store from './store'
import {
  FormPlugin,
  ModalPlugin
} from 'bootstrap-vue'
import './scss/custom.scss'

import VueAnalytics from 'vue-analytics'

Vue.use(ModalPlugin);
Vue.use(FormPlugin);

import messages from './i18n/messages'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: document.documentElement.lang,
  messages
});

Vue.use(VueAnalytics, {
  id: 'UA-143333920-1' //'UA-83712697-9'
});

Vue.config.productionTip = false;

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    i18n,
    store,
    render: h => h(App)
  }).$mount('#callback_wid');
});
