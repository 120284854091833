<template>
  <div id="callback" class="m-auto">
    <base-callback v-b-modal.callback_modal/>

    <b-modal
      id="callback_modal"
      hide-footer
      hide-header
    >
      <base-callback-widget
        :ok="$t('message.call_me')"
        @exit="$bvModal.hide('callback_modal')"
      />
    </b-modal>
  </div>
</template>

<script>
  // @ is an alias to /src
  import BaseCallback from './components/BaseCallback'
  import BaseCallbackWidget from './components/BaseCallbackWidget.vue'

  export default {
    name: 'Callback',
    components: {
      BaseCallback, BaseCallbackWidget
    },
    data: () => ({
      date: Math.trunc(new Date().getTime() / 1000) + 30
    })
  }
</script>

<style lang="scss">
</style>
