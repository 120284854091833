export default {
  ru: {
    message: {
      base_callback_widget__header: 'Оставьте запрос на обратный звонок',
      base_callback_widget__text: 'Мы перезвоним вам и поможем!',
      base_callback_widget__its_free: 'Звонок бесплатный',
      base_callback_widget__call_you: 'Перезвоним вам через:',
      base_callback_widget__is_success: 'Удалось ли менеджеру дозвониться до вас?',
      base_callback_widget__nobody: 'В данный момент в офисе никого',
      base_callback_widget__nobody_text: 'Оставьте заявку и мы перезвоним вам в рабочее время',
      base_callback__text_1: 'Закажите в один клик',
      base_callback__text_2: 'обратный звонок специалиста',
      base_callback__text_3: 'Мы перезвоним немедленно',
      call_me: 'Перезвоните мне',
      yes: 'Да',
      no: 'Нет'
    }
  },
  en: {
    message: {
      base_callback_widget__header: 'Leave your request',
      base_callback_widget__text: 'We\'ll call you and help!',
      base_callback_widget__its_free: 'It\'s free for you',
      base_callback_widget__call_you: 'We\'ll call you for:',
      base_callback_widget__is_success: 'Did our manager call you?',
      base_callback_widget__nobody: 'There\'s nobody in the office now',
      base_callback_widget__nobody_text: 'Leave your request so we\'ll call you at the work-time at',
      base_callback__text_1: 'Request a callback',
      base_callback__text_2: 'in one click',
      base_callback__text_3: 'We\'ll call you in no time',
      call_me: 'Call me',
      yes: 'Yes',
      no: 'No'
    }
  }
}
